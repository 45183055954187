import React from 'react';
import TagManager from 'react-gtm-module'

import ScrollToTop from './components/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "react-awesome-lightbox/build/style.css";
import 'swiper/swiper.min.css';
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import "swiper/modules/pagination/pagination.min.css";
import 'react-bootstrap-accordion/dist/index.css'

import './index.css';
import App from './App';
import CookieConsent from 'react-cookie-consent';
import { hydrate, render } from "react-dom";

const gtmId = process.env.REACT_APP_COMPANY &&
  process.env.REACT_APP_COMPANY === "r2cloud" ? 'GTM-55JTRTN' : 'GTM-NN4D985';

const tagManagerArgs = { gtmId }

TagManager.initialize(tagManagerArgs)


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<BrowserRouter>
    <ScrollToTop />
    <App />
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="www.r2c.io"
      style={{ background: "#2B373B", zIndex: '9999999' }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      R2 Consulting (www.r2c.io) uses cookies to offer you the most relevant information.
      <br />
      <div className="cookie-banner-links">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/cookie-policy">Cookie Policy</Link>
        <Link to="/terms">Terms of use</Link>
      </div>
    </CookieConsent>
  </BrowserRouter>, rootElement);
} else {
  render(<BrowserRouter>
    <ScrollToTop />
    <App />
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="www.r2c.io"
      style={{ background: "#2B373B", zIndex: '9999999' }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      R2 Consulting (www.r2c.io) uses cookies to offer you the most relevant information.
      <br />
      <div className="cookie-banner-links">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/cookie-policy">Cookie Policy</Link>
        <Link to="/terms">Terms of use</Link>
      </div>
    </CookieConsent>
  </BrowserRouter>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
